<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer :  추천 상품 결과 노출 - 정보 불러오기 -->
    <section id="modalResultItemAdd" class="layer_dialog modalin">
      <h2 class="mb20">추천 상품 저장된 노출항목 불러오기</h2>

      <select name="" v-model="select_item" style="width: 274px">
        <option value="" disabled>선택</option>
        <option v-for="(item, key) of this.item_list" :key="key" :value="item">
          [{{ item.type === 'text' ? '텍스트' : '이미지' }}]
          {{ optionTextLength(item.title, 15) }}
        </option>
      </select>
      <button
        type="button"
        @click="addItem"
        class="btn btn_large btn_light ml5"
      >
        추가
      </button>
      <ul class="item_list">
        <li v-for="(item, key) of this.select_item_list" :key="key">
          <button type="button" @click="deleteItem(key)" name="">
            <span class="material-icons">cancel</span>
          </button>
          [{{ item.type === 'text' ? '텍스트' : '이미지' }}] {{ item.title }}
        </li>
      </ul>

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 추천 상품 결과 노출 - 정보 불러오기 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    show_modal: true,
    item_list: [],
    select_item: '',
    select_item_list: [],
    params: null,
  }),
  props: ['survey_config_info', 'result'],
  created: async function () {
    this.init();
  },
  methods: {
    init: function () {
      this.select_item_list = [];
      this.item_list = JSON.parse(this.survey_config_info.result_item);
      if (
        this.result.show_items !== null &&
        this.result.show_items !== undefined
      ) {
        this.select_item_list = this.result.show_items;
      }

      this.params = this.result;
    },
    setResult: function () {
      if (this.select_item_list.length === 0) {
        alert('결과 화면 노출 항목을 추가해주세요.');
        return false;
      }
      this.params.show_items = this.select_item_list;
      this.$emit('updateData', this.params);
      this.show_modal = false;
    },
    addItem: function () {
      if (
        this.select_item === '' ||
        this.select_item === null ||
        this.select_item === undefined
      ) {
        alert('노출항목을 선택해주세요.');
        return false;
      }

      if (this.select_item !== '') {
        let isAdded = false;

        this.select_item_list.find((element, index) => {
          if (
            element.type === this.select_item.type &&
            element.title === this.select_item.title
          ) {
            isAdded = true;
          }
        });

        if (isAdded === true) {
          alert('이미 추가한 노출 항목입니다.');
          return false;
        } else {
          this.select_item_list.push(this.select_item);
        }
      }
    },
    deleteItem: function (i) {
      this.select_item_list.splice(i, 1);
    },

    optionTextLength: function (text, length) {
      if (!length || length == 0) {
        length = 25;
      }
      if (text.length > length) {
        return text.substr(0, length) + '...';
      } else {
        return text;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn.btn_light {
  background-color: #4b5563;
  color: #ffff;
}
</style>
